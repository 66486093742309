import api from '@/app/api'

export default {
  async getProducts() {
    const response = await api.get('/product')
    return response
  },

  async createProduct(productData) {
    const response = await api.post('/product', productData)
    return response
  },

  async getProductById(id) {
    const response = await api.get(`/product/${id}`)
    return response
  },

  async editProduct(id, productData) {
    const response = await api.put(`/product/${id}`, productData)
    return response
  },

  async deleteProduct(id) {
    const response = await api.delete(`/product/${id}`)
    return response
  },
}
